import { useState, useEffect } from "react"
import { Frame } from "framer"

export function TimeBasedIcon(props) {
    const [icon, setIcon] = useState("morning") // default to morning

    useEffect(() => {
        const hours = new Date().getHours()
        if (hours >= 6 && hours < 12) {
            setIcon("morning")
        } else if (hours >= 12 && hours < 18) {
            setIcon("afternoon")
        } else {
            setIcon("evening")
        }
    }, [])

    const getIcon = () => {
        switch (icon) {
            case "morning":
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 17.2 17.2"
                    >
                        <path
                            d="M3.8 8.6a4.8 4.8 0 1 0 4.8-4.8 4.8 4.8 0 0 0-4.8 4.8Zm4.8-6.933V.6m4.903 3.098.754-.754M15.533 8.6H16.6m-3.097 4.903.754.754M8.6 15.533V16.6m-4.902-3.097-.754.754M1.667 8.6H.6m3.098-4.902-.754-.754"
                            fill="none"
                            stroke="#5fb4f2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.2"
                        />
                    </svg>
                )
            case "afternoon":
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 17.2 17.2"
                    >
                        <path
                            d="M3.8 8.6a4.8 4.8 0 1 0 4.8-4.8 4.8 4.8 0 0 0-4.8 4.8Zm4.8-6.933V.6m4.903 3.098.754-.754M15.533 8.6H16.6m-3.097 4.903.754.754M8.6 15.533V16.6m-4.902-3.097-.754.754M1.667 8.6H.6m3.098-4.902-.754-.754"
                            fill="none"
                            stroke="#5fb4f2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.2"
                        />
                    </svg>
                )
            case "evening":
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 16.663 17.206"
                    >
                        <path
                            d="M12.508 11.861a7.706 7.706 0 0 1-7.369-9.956 7.7 7.7 0 1 0 10.589 9.249 7.656 7.656 0 0 1-3.22.71ZM8.359 2.378h3.556M10.137.6v3.556m3.556 1.778h2.371m-1.186-1.185V7.12"
                            fill="none"
                            stroke="#5fb4f2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.2"
                        />
                    </svg>
                )
            default:
                return null
        }
    }

    return <Frame background="transparent">{getIcon()}</Frame>
}
